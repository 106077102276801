import { NMAAHCPropTypes, PageTypes, ThemeContext } from "assets";
import { pillarSiblingNavAugmentor } from "atoms";
import { graphql } from "gatsby";
import {
  addExhibitionsParent,
  buildGalleryData,
  convertBreadcrumbNavigation,
  convertDirectionNavigation,
  convertHero,
  convertSiblingNavigation,
} from "molecules";
import { convertComponentList, PageLayout } from "organisms";
import PropTypes from "prop-types";
import React from "react";

const PillarPage = ({ data, sectionOnly, fontType, presentationType }) => {
  const pillarData = pillarSiblingNavAugmentor(data?.craftAPI?.entry);

  buildGalleryData(pillarData);
  const children = convertComponentList(pillarData?.componentList);
  const pillarTheme = pillarData.theme || pillarData.parent?.theme;
  const pillarFontType = pillarData.fontType || pillarData.parent?.fontType;
  const pillarTitleColor = pillarData.pillarTitleColor;

  const hasChapterListing = pillarData.componentList.filter((c) => {
    return c.__typename === "CraftAPI_componentList_chapterListing_BlockType";
  });
  if (!hasChapterListing.length) {
    const chapterNavigation = {
      condensed: true,
      typeHandle: "chapter",
      pages: pillarData.children,
      chronoPresentation:
        pillarData.parent?.presentationType !==
        NMAAHCPropTypes.EXHIBIT_TYPE_THEMED,
    };

    if (pillarData.children?.length) {
      children.push(convertSiblingNavigation(chapterNavigation));
    }
  }

  // Determine the chapter number
  let pillarNum = pillarData.parent?.children?.findIndex(
    (child) => child.id === pillarData.id
  );

  // Place hero at the top of Pillar
  children.unshift(
    convertHero({
      header:
        pillarNum > -1 &&
        pillarData.parent?.presentationType !==
          NMAAHCPropTypes.EXHIBIT_TYPE_THEMED
          ? `Part ${String(pillarNum + 1).padStart(2, "0")}`
          : undefined,
      fontType: pillarData.parent?.fontType || fontType,
      presentationType: pillarData.parent?.presentationType || presentationType,
      titleSize: pillarData.parent?.titleSize,
      ...pillarData,
    })
  );

  if (sectionOnly) {
    return <section>{children}</section>;
  } else {
    children.unshift(
      convertBreadcrumbNavigation({
        pageTitle: pillarData.title,
        parents: addExhibitionsParent(pillarData.parent),
      })
    );
    children.push(convertDirectionNavigation(pillarData));

    return (
      <ThemeContext.Provider
        value={{
          theme: pillarTheme,
          fontType: pillarFontType,
          pillarTitleColor: pillarTitleColor,
        }}
      >
        <PageLayout
          coverImage={pillarData.coverImage}
          pageType={PageTypes.EXHIBITION}
          presentationType={pillarData.parent?.presentationType}
          shortDescription={pillarData.shortDescription}
          theme={pillarTheme}
          title={pillarData.title}
        >
          {children}
        </PageLayout>
      </ThemeContext.Provider>
    );
  }
};

PillarPage.propTypes = {
  data: PropTypes.shape({
    craftAPI: PropTypes.shape({
      entry: PropTypes.shape({}),
    }),
  }),
  fontType: NMAAHCPropTypes.FontType,
  presentationType: NMAAHCPropTypes.ExhibitType,
  sectionOnly: PropTypes.bool,
};

export const pillarQuery = graphql`
  query ($uri: [String], $section: [String] = "exhibit") {
    craftAPI {
      entry(uri: $uri) {
        id
        ...BreadcrumbNavigationFragment
        ... on CraftAPI_exhibit_pillar_Entry {
          coverImage {
            ...ImageMetadataFragment
          }
          audioAsset {
            ...AudioAssetFragment
          }
          heroComponent {
            ...VideoHeroFragment
          }
          componentList {
            ...ComponentListFragment
          }
          ...DirectionNavigationFragment
          # Exhibit
          parent {
            ... on CraftAPI_exhibit_exhibit_Entry {
              id
              fontType
              presentationType
              sectionId
              theme
              title
              titleSize
              uri
              # Sibling Pillars
              children {
                ...PillarNavigationFragment
              }
            }
          }
          # Chapter
          children {
            ...ChapterNavigationFragment
          }
          heroType
          shortDescription
          longDescription
          subtitle
          backgroundImage {
            url
          }
          theme
          pillarTitleColor
          fontType
          fontColor
          formattedTitleOverride
        }
      }
    }
  }
`;

export default PillarPage;
